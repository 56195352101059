import React from "react";
import '../styles/long1.css';
import '../styles/gogogo.css';
// const checkPageURL = () =>{
//   if (typeof window !== 'undefined' && window.location.pathname == '/gogogo-thankyou'){
//     return(
//       <meta httpEquiv="refresh" content="1; url='/gogogo-ended'" />
//     )
//   }
// };

// const IndexPage = () => (
//     <head>
//     <meta http-equiv="refresh" content="1; url='/gogogo-ended'" />
//   </head>
//   );

// export default IndexPage;

const IndexPage = () => (
    <div class="grid-container">
    <div className="header">
        <div className="header1"> 
            <div className="logo-div go-logo-div">
                <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
            </div>
        </div>
        <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
   </div>
    <div class="left_space"></div>
    <div className="short1-main">
        <div className="gogogo-ty-bg">
          <div className="go-headSection">
            <p className="gopage-ty-header">Thank You for Entering the TEXpress Lanes Holiday Sweepstakes!</p>
            <p className="gopage-ty-text">Your profile is complete and you're on your way to more reliable, faster commutes.<br /><br />Winners will be announced the week of January 17, 2022, so stay tuned and enjoy the ride!</p>
            <a className="go-linkStyle go-ty-btn" href="https://www.texpresslanes.com/using-the-lanes/how-to-use/"><p className="go-ty-button">LEARN THE LANES</p></a>
        </div>
        </div>
    </div>  
  
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2021 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default IndexPage;